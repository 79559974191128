import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet";
import { ModalDescarga } from "./ModalDescarga";
const faqs = [
    {
        question: "Devo pagare per scaricare una canzone da Spotify?",
        answer: "No, non devi pagare nulla, perché il nostro servizio di download di brani da Spotify in formato Mp3 è sempre gratuito! Supportiamo tutti i browser moderni come Google Chrome, Mozilla Firefox, Safari, Microsoft Edge, ecc.",
    },
    {
        question:
            "È necessario installare estensioni per utilizzare il downloader di brani da Spotify?",
        answer: "No. Per salvare Spotify in mp3 online, hai solo bisogno di un link. Incollalo nel campo di input e clicca su Download. La nostra app Spotify Downloader farà il resto.",
    },
    {
        question: "Perché non riesco a scaricare le canzoni di Spotify?",
        answer: "Forse hai copiato il link di Spotify sbagliato o la connessione di rete è instabile.",
    },
    {
        question:
            "È possibile scaricare più canzoni contemporaneamente su SpotifyUnload?",
        answer: "È completamente illimitato, non limitiamo i download di SpotifyUnload, puoi scaricare qualsiasi brano di Spotify come Mp3.",
    },
    {
        question: "SpotifyUnload conserva una copia della canzone scaricata?",
        answer: "Non facciamo il backup di nessuna informazione utente, nemmeno video personali, quindi puoi stare tranquillo che SpotifyUnload Spotify Downloader è completamente sicuro.",
    },
    {
        question:
            "Dove vengono salvati i file Mp3 di Spotify dopo il download?",
        answer: "Quando salvi da Spotify in Mp3, i file vengono di solito salvati nella tua posizione predefinita. Nelle impostazioni del tuo browser, puoi cambiare e selezionare manualmente la cartella di destinazione per i tuoi file.",
    },
    {
        question: "SpotifyUnload supporta il download di playlist di Spotify?",
        answer: "Abbiamo il supporto per scaricare playlist di Spotify. Incolla il link della playlist e clicca su Download. Puoi anche scaricare album di Spotify.",
    },
];
export const HomeIta = () => {
    const [input, setInput] = useState("");
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [urlValida, setUrlValida] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [downloadError, setDownloadError] = useState(null);
    const toggleQuestion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };
    const [cargadoCancion, setCargandoCancion] = useState(false);
    const [isTrack, setIsTrack] = useState(null);
    const [isPlaylist, setIsPlaylist] = useState(null);
    const [isAlbum, setIsAlbum] = useState(null);
    const [modal, setModal] = useState(false);

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            setInput(text);
        });
    };

    const hacerLlamadaApi = async (url) => {
        let urlFormat = url?.trim();
        window.scrollTo(0, 0);
        if (urlFormat === "") {
            console.log("URL non valide");
            setUrlValida(false);
            return;
        }
        const regex =
            /^https:\/\/open\.spotify\.com\/(?:intl-es\/)?(track|playlist|album)\/([a-zA-Z0-9]+)(?:\?.*)?$/;
        const match = urlFormat?.match(regex);

        if (!match) {
            console.log("URL non valide");
            setUrlValida(false);
            return;
        }

        // Extraire le type et l'ID de la correspondance
        const [, tipo, id] = match;

        setCargando(true);
        setIsTrack(null);
        setIsPlaylist(null);
        setIsAlbum(null);
        setUrlValida(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROXY}/buscar-cancion`,

                {
                    idCancion: id,
                    tipo: tipo,
                }
            );

            if (tipo === "track") {
                setIsTrack(response.data);
            } else if (tipo === "playlist") {
                setIsPlaylist(response.data);
            } else if (tipo === "album") {
                setIsAlbum(response.data);
            } else {
                setUrlValida(false);
            }
            setCargando(false);
        } catch (error) {
            console.error(error);
            setUrlValida(false);
            setCargando(false);
        }
    };

    const handleDownload = async () => {
        setModal(true);

        setCargandoCancion(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_PROXY}/descargar-cancion/${isTrack.urlDescarga}/${isTrack.nombreCancion}`
            );

            if (!response.ok) {
                throw new Error("Errore nel scaricare il file");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `SpotifyUnload.com - ${isTrack.nombreCancion}.m4a`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setCargandoCancion(false);
        } catch (error) {
            setCargandoCancion(false);
            console.error("Errore nel scaricare il file:", error);
            setDownloadError("Errore nel scaricare il file");
        }
    };

    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    // useEffect(() => {
    //     script1.text = `
    //         atOptions = {
    //             'key' : '201479128385db68aa1859ac8b99981e',
    //             'format' : 'iframe',
    //             'height' : 60,
    //             'width' : 468,
    //             'params' : {}
    //         };
    //     `;

    //     script2.src =
    //         "//www.topcreativeformat.com/201479128385db68aa1859ac8b99981e/invoke.js";

    //     const adContainer = document.getElementById("adContainer");
    //     if (adContainer) {
    //         adContainer.appendChild(script1);
    //         adContainer.appendChild(script2);
    //     }

    //     // Limpiar al desmontar
    //     return () => {
    //         if (adContainer) {
    //             adContainer.removeChild(script1);
    //             adContainer.removeChild(script2);
    //         }
    //     };
    // }, []);

    return (
        <div className="pt-4 pb-10 select-none ">
            {modal && <ModalDescarga setModal={setModal} />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    SpotifyUnload - Scaricatore gratuito di musica Spotify
                </title>
                <link rel="icon" href="favicon.ico" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="⬇️🎶 SpotifyUnload è uno strumento gratuito che ti aiuta a scaricare brani da Spotify in MP3 online. Salva brani di Spotify con la migliore qualità in un file MP3. Il nostro Spotify Music Downloader è completamente gratuito, veloce e sicuro."
                />
                <link href="/dist/output.css" rel="stylesheet" />
                <link
                    rel="apple-touch-icon"
                    href="android-chrome-192x192.png"
                />

                <meta
                    name="keywords"
                    content="Spotify, Musica, Scaricatore, MP3, Gratis, Download"
                />

                <link rel="manifest" href="manifest.json" />
            </Helmet>

            <NavBar />
            <div className="mx-4 mt-8">
                <h3 className="font-serif text-2xl font-semibold xs:text-3xl">
                    Spotify Downloader Online
                </h3>
                <h3 className="mt-4 font-serif text-xl font-medium">
                    Scarica Spotify in MP3 gratuitamente
                </h3>
            </div>
            <div
                className="flex justify-center w-full mx-auto"
                id="adContainer"></div>
            <div className="relative flex flex-col items-center justify-center mt-8 xs:flex-row">
                {!urlValida && (
                    <div className="absolute z-20 p-2 text-white transform -translate-x-1/2 bg-red-500 rounded-md -top-10 left-1/2">
                        L'URL inserito non è valido. Per favore, controlla e
                        riprova.
                    </div>
                )}
                <div className="relative w-11/12 max-w-2xl xs:w-9/12 md:w-6/12">
                    <input
                        placeholder="https://open.spotify.com/..../...."
                        className="border-4 border-[#191414] rounded-md xs:rounded-none xs:rounded-l-md py-4 w-full h-16 px-3 text-lg relative z-10"
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button
                        className="border-4 border-[#191414] rounded-md py-1 xs:py-2 px-1 xs:px-3  text-[#1DB954] font-bold xs:text-lg h-10 xs:h-12 bg-[#191414] flex justify-center items-center gap-2 absolute right-2 top-3 xs:top-2 bottom-0 z-10 hover:scale-105"
                        onClick={handlePaste}>
                        <p>Incolla</p>
                        <span>
                            <svg
                                className="xs:h-6 xs:w-6 h-5 w-5  text-[#1DB954]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <rect
                                    x="8"
                                    y="4"
                                    width="12"
                                    height="12"
                                    rx="2"
                                />{" "}
                                <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                            </svg>
                        </span>
                    </button>
                </div>
                <button
                    disabled={cargando}
                    className=" disabled:cursor-not-allowed border-4 border-[#191414] mt-4 xs:mt-0 rounded-md xs:rounded-e-md xs:rounded-none py-3 px-3 text-[#1DB954] font-bold text-lg h-16 bg-[#191414] flex justify-center items-center gap-2"
                    onClick={() => hacerLlamadaApi(input)}>
                    <p>{!cargando ? "Scarica" : "Scaricando..."}</p>
                    <span>
                        <svg
                            className={`h-6 w-6 text-[#1DB954] ${
                                cargando && "animate-bounce"
                            } `}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                        </svg>
                    </span>
                </button>
            </div>
            {isTrack && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isTrack.imagenCancion}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isTrack.nombreCancion}
                        </p>
                        <p className="text-lg font-medium">
                            {isTrack.nombreCantante}
                        </p>
                        <div>
                            <button
                                disabled={cargadoCancion}
                                onClick={handleDownload}
                                className="bg-[#1DB954] rounded-lg text-white text-lg font-medium py-2 px-10 hover:scale-105 disabled:cursor-not-allowed">
                                {cargadoCancion ? "Scaricando..." : "Scarica"}
                            </button>
                            {downloadError && <p>{downloadError}</p>}
                        </div>
                    </div>
                </div>
            )}
            {isPlaylist && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isPlaylist.imagenPlaylist}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isPlaylist.nombrePlaylist}
                        </p>
                        <div className="w-full px-4">
                            {isPlaylist.cancionesPlaylist?.map(
                                (cancion, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between w-full mb-4">
                                        <div className="font-semibold xs:text-lg">
                                            <div className="text-[#1DB954]">
                                                {index + 1}: {cancion.nombre}
                                            </div>
                                            <div>{cancion.artista}</div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() =>
                                                    hacerLlamadaApi(
                                                        `https://open.spotify.com/intl-it/track/${cancion.id}`
                                                    )
                                                }
                                                className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                                Scarica
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isAlbum && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isAlbum.imagenAlbum}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isAlbum.nombreAlbum}
                        </p>
                        <p className="text-lg font-medium">
                            {isAlbum.nombreCantante}
                        </p>
                        <div className="w-full px-4">
                            {isAlbum.cancionesAlbum?.map((cancion, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between w-full mb-4">
                                    <div className="font-semibold xs:text-lg">
                                        <div className="text-[#1DB954]">
                                            {index + 1}: {cancion.nombre}
                                        </div>
                                        <div>{cancion.artista}</div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() =>
                                                hacerLlamadaApi(
                                                    `https://open.spotify.com/intl-it/track/${cancion.id}`
                                                )
                                            }
                                            className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                            Scarica
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Cos'è SpotifyUnload Spotify Downloader?
                </h2>
                <p className="text-[#191414] text-lg mt-2">
                    SpotifyUnload è uno strumento gratuito che ti aiuta a
                    scaricare brani Spotify in formato MP3 online. Salva brani
                    Spotify con la migliore qualità in formato file MP3. Il
                    nostro Spotify Music Downloader è completamente gratuito,
                    veloce e sicuro. Non è necessario installare app o
                    estensioni, devi solo avere un link di brano, playlist o
                    album di Spotify da incollare nel campo di input sul sito
                    web di SpotifyUnload Spotify Music Downloader e scaricare il
                    file MP3.
                </p>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Caratteristiche - SpotifyUnload salva brani Spotify in mp3:
                </h2>
                <ul className="list-disc px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Sempre stabile e facile da usare, aggiornato
                        continuamente per la migliore esperienza
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Nessuna necessità di scaricare estensioni, prova invece
                        la nostra funzionalità PWA (Progressive Web Apps) per la
                        migliore esperienza.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Supporta tutti i browser e dispositivi personali come
                        telefoni, computer e tablet.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Facile da usare, veloce e sempre gratuito.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        SpotifyUnload ti offre un modo semplice ed efficiente
                        per scaricare gratuitamente i tuoi brani Spotify
                        preferiti.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414] text-2xl font-semibold">
                    Come scaricare brani/playlist/album Spotify online?
                </h2>
                <ul className="list-decimal px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Apri l'app Spotify o il sito web di Spotify nel tuo
                        browser.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Apri la playlist o l'album e seleziona la canzone che
                        desideri salvare come file MP3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Clicca sui{" "}
                            <span className="flex items-end text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="19" cy="12" r="1" />{" "}
                                        <circle cx="5" cy="12" r="1" />
                                    </svg>
                                </span>
                                Tre Punti{" "}
                            </span>{" "}
                            e clicca sul pulsante{" "}
                            <span className="flex items-end text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="6" cy="12" r="3" />{" "}
                                        <circle cx="18" cy="6" r="3" />{" "}
                                        <circle cx="18" cy="18" r="3" />{" "}
                                        <line
                                            x1="8.7"
                                            y1="10.7"
                                            x2="15.3"
                                            y2="7.3"
                                        />{" "}
                                        <line
                                            x1="8.7"
                                            y1="13.3"
                                            x2="15.3"
                                            y2="16.7"
                                        />
                                    </svg>
                                </span>
                                Condividi{" "}
                            </span>{" "}
                            .
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Seleziona{" "}
                            <span className="flex items-end text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <rect
                                            x="8"
                                            y="4"
                                            width="12"
                                            height="12"
                                            rx="2"
                                        />{" "}
                                        <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                                    </svg>
                                </span>
                                Copia il link della canzone{" "}
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Visita il sito web{" "}
                            <span className="flex items-end text-[#1DB954] mx-2">
                                SpotifyUnload.com, Spotify Downloader in MP3.
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Incolla il link nel campo di input sopra che dice
                        "Incolla l'URL da Spotify".
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Clicca sul pulsante di download a destra per scaricare
                        l'Mp3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Seleziona la qualità Mp3 per scaricare la musica Spotify
                        in Mp3.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>

            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414] text-2xl font-semibold mt-8">
                    FAQ
                </h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="mt-4">
                        <div
                            className="px-2 py-2 transition duration-100 ease-in-out border-2 rounded-md cursor-pointer"
                            onClick={() => toggleQuestion(index)}>
                            <h3 className="text-[#1DB954] text-lg font-medium select-none">
                                {faq.question}
                            </h3>
                        </div>
                        {activeQuestion === index && (
                            <p className="text-[#191414] text-lg mt-2 transition duration-100 ease-in-out">
                                {faq.answer}
                            </p>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};
