import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet";
import { ModalDescarga } from "./ModalDescarga";
const faqs = [
    {
        question: "Do I have to pay to download Spotify song?",
        answer: "No, you don't have to pay for anything, because our download Spotify song to Mp3 service is always free! We support all modern browsers such as Google Chrome, Mozilla Firefox, Safari, Microsoft Edge etc.",
    },
    {
        question:
            "Do I need to install extensions to use Spotify song downloader?",
        answer: "No. To save Spotify to mp3 online, you just need a link. Paste it into the input field and click Download. Our Spotify Downloader app will do the rest.",
    },
    {
        question: "Why can't i download Spotify songs?",
        answer: "Maybe you copied the wrong spotify link, or the network connection is unstable.",
    },
    {
        question:
            "Is it possible to download multiple songs at once at SpotifyUnload?",
        answer: "It's completely unlimited, we don't limit the downloads of SpotifyUnload you can download any Spotify song as an Mp3.",
    },
    {
        question: "Does SpotifyUnload keep a copy of the downloaded song?",
        answer: "We do not back up any user information, even personal videos, so you can rest assured that SpotifyUnload Spotify Downloader is completely secure.",
    },
    {
        question: "Where are Spotify Mp3 saved after downloading?",
        answer: "When you save from Spotify to Mp3, files are usually saved to your default location. In your browser settings, you can change and manually select the destination folder for your files.",
    },
    {
        question: "Does SpotifyUnload support Download Spotify Playlist?",
        answer: "We have support to download spotify playlist from Spotify. Just paste the playlist link and click Download. You can also download spotify album.",
    },
];
export const Home = () => {
    const [input, setInput] = useState("");
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [urlValida, setUrlValida] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [downloadError, setDownloadError] = useState(null);
    const toggleQuestion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };
    const [modal, setModal] = useState(false);
    const [cargadoCancion, setCargandoCancion] = useState(false);
    const [isTrack, setIsTrack] = useState(null);
    const [isPlaylist, setIsPlaylist] = useState(null);
    const [isAlbum, setIsAlbum] = useState(null);

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            setInput(text);
        });
    };
    const hacerLlamadaApi = async (url) => {
        let urlFormat = url?.trim();
        window.scrollTo(0, 0);
        if (urlFormat === "") {
            console.log("URL no válida");
            setUrlValida(false);
            return;
        }
        const regex =
            /^https:\/\/open\.spotify\.com\/(?:intl-es\/)?(track|playlist|album)\/([a-zA-Z0-9]+)(?:\?.*)?$/;
        const match = urlFormat?.match(regex);

        if (!match) {
            console.log("URL no válida");
            setUrlValida(false);
            return;
        }

        // Extraer el tipo y el ID de la coincidencia
        const [, tipo, id] = match;

        setCargando(true);
        setIsTrack(null);
        setIsPlaylist(null);
        setIsAlbum(null);
        setUrlValida(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROXY}/buscar-cancion`,

                {
                    idCancion: id,
                    tipo: tipo,
                }
            );

            if (tipo === "track") {
                setIsTrack(response.data);
            } else if (tipo === "playlist") {
                setIsPlaylist(response.data);
            } else if (tipo === "album") {
                setIsAlbum(response.data);
            } else {
                setUrlValida(false);
            }
            setCargando(false);
        } catch (error) {
            console.error(error);
            setUrlValida(false);
            setCargando(false);
        }
    };

    const handleDownload = async () => {
        setCargandoCancion(true);
        setModal(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_PROXY}/descargar-cancion/${isTrack.urlDescarga}/${isTrack.nombreCancion}`
            );

            if (!response.ok) {
                throw new Error("Error al descargar el archivo");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `SpotifyUnload.com - ${isTrack.nombreCancion}.m4a`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setCargandoCancion(false);
        } catch (error) {
            setCargandoCancion(false);
            console.error("Error al descargar el archivo:", error);
            setDownloadError("Error al descargar el archivo");
        }
    };
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    // useEffect(() => {
    //     script1.text = `
    //         atOptions = {
    //             'key' : '201479128385db68aa1859ac8b99981e',
    //             'format' : 'iframe',
    //             'height' : 60,
    //             'width' : 468,
    //             'params' : {}
    //         };
    //     `;

    //     script2.src =
    //         "//www.topcreativeformat.com/201479128385db68aa1859ac8b99981e/invoke.js";

    //     const adContainer = document.getElementById("adContainer");
    //     if (adContainer) {
    //         adContainer.appendChild(script1);
    //         adContainer.appendChild(script2);
    //     }

    //     // Limpiar al desmontar
    //     return () => {
    //         if (adContainer) {
    //             adContainer.removeChild(script1);
    //             adContainer.removeChild(script2);
    //         }
    //     };
    // }, []);

    return (
        <div className="pt-4 pb-10 select-none ">
            {modal && <ModalDescarga setModal={setModal} />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>SpotifyUnload - Free Spotify Music Downloader</title>
                <link rel="icon" href="favicon.ico" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="⬇️🎶 SpotifyUnload is a free tool helping you to download Spotify songs in MP3 online. Save Spotify songs with the best quality in an MP3 file format. Our Spotify Music Downloader is completely free to use, fast, and secure."
                />
                <link href="/dist/output.css" rel="stylesheet" />
                <link
                    rel="apple-touch-icon"
                    href="android-chrome-192x192.png"
                />

                <meta
                    name="keywords"
                    content="Spotify, Music, Downloader, MP3, Free, Download"
                />

                <link rel="manifest" href="manifest.json" />
            </Helmet>
            <NavBar />
            <div className="mx-4 mt-8">
                <h3 className="font-serif text-2xl font-semibold xs:text-3xl">
                    Spotify Downloader Online
                </h3>
                <h3 className="mt-4 font-serif text-xl font-medium">
                    Download Spotify to MP3 For Free
                </h3>
            </div>
            <div
                className="flex justify-center w-full mx-auto"
                id="adContainer"></div>

            <div className="relative flex flex-col items-center justify-center mt-8 xs:flex-row">
                {!urlValida && (
                    <div className="absolute z-20 p-2 text-white transform -translate-x-1/2 bg-red-500 rounded-md -top-10 left-1/2">
                        The URL entered is invalid. Please check it and try
                        again.
                    </div>
                )}
                <div className="relative w-11/12 max-w-2xl xs:w-9/12 md:w-6/12">
                    <input
                        placeholder="https://open.spotify.com/..../...."
                        className="border-4 border-[#191414] rounded-md xs:rounded-none xs:rounded-l-md py-4 w-full h-16 px-3 text-lg relative z-10"
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button
                        className="border-4 border-[#191414] rounded-md py-1 xs:py-2 px-1 xs:px-3  text-[#1DB954] font-bold xs:text-lg h-10 xs:h-12 bg-[#191414] flex justify-center items-center gap-2 absolute right-2 top-3 xs:top-2 bottom-0 z-10 hover:scale-105"
                        onClick={handlePaste}>
                        <p>Paste</p>
                        <span>
                            <svg
                                className="xs:h-6 xs:w-6 h-5 w-5  text-[#1DB954]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <rect
                                    x="8"
                                    y="4"
                                    width="12"
                                    height="12"
                                    rx="2"
                                />{" "}
                                <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                            </svg>
                        </span>
                    </button>
                </div>
                <button
                    disabled={cargando}
                    className=" disabled:cursor-not-allowed border-4 border-[#191414] mt-4 xs:mt-0 rounded-md xs:rounded-e-md xs:rounded-none py-3 px-3 text-[#1DB954] font-bold text-lg h-16 bg-[#191414] flex justify-center items-center gap-2"
                    onClick={() => hacerLlamadaApi(input)}>
                    <p>{!cargando ? "Download" : "Downloading..."}</p>
                    <span>
                        <svg
                            className={`h-6 w-6 text-[#1DB954] ${
                                cargando && "animate-bounce"
                            } `}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                        </svg>
                    </span>
                </button>
            </div>

            {isTrack && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isTrack.imagenCancion}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isTrack.nombreCancion}
                        </p>
                        <p className="text-lg font-medium">
                            {isTrack.nombreCantante}
                        </p>
                        <div>
                            <div className="flex justify-center w-full">
                                <button
                                    disabled={cargadoCancion}
                                    onClick={handleDownload}
                                    className="bg-[#1DB954] rounded-lg text-white text-lg font-medium py-2 px-10 hover:scale-105 disabled:cursor-not-allowed mx-auto">
                                    {cargadoCancion
                                        ? "Downloading..."
                                        : "Download"}
                                </button>
                                {downloadError && <p>{downloadError}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isPlaylist && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isPlaylist.imagenPlaylist}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isPlaylist.nombrePlaylist}
                        </p>
                        <div className="w-full px-4">
                            {isPlaylist.cancionesPlaylist?.map(
                                (cancion, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between w-full mb-4">
                                        <div className="font-semibold xs:text-lg">
                                            <div className="text-[#1DB954]">
                                                {index + 1}: {cancion.nombre}
                                            </div>
                                            <div>{cancion.artista}</div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() =>
                                                    hacerLlamadaApi(
                                                        `https://open.spotify.com/intl-es/track/${cancion.id}`
                                                    )
                                                }
                                                className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                                Download
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isAlbum && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isAlbum.imagenAlbum}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isAlbum.nombreAlbum}
                        </p>
                        <p className="text-lg font-medium">
                            {isAlbum.nombreCantante}
                        </p>
                        <div className="w-full px-4">
                            {isAlbum.cancionesAlbum?.map((cancion, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between w-full mb-4">
                                    <div className="font-semibold xs:text-lg">
                                        <div className="text-[#1DB954]">
                                            {index + 1}: {cancion.nombre}
                                        </div>
                                        <div>{cancion.artista}</div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() =>
                                                hacerLlamadaApi(
                                                    `https://open.spotify.com/intl-es/track/${cancion.id}`
                                                )
                                            }
                                            className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                            Download
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    What is SpotifyUnload Spotify Downloader?
                </h2>
                <p className="text-[#191414] text-lg mt-2">
                    SpotifyUnload is a free tool helping you to download Spotify
                    songs in MP3 online. Save Spotify songs with the best
                    quality in an MP3 file format. Our Spotify Music Downloader
                    is completely free to use, fast and secure. No need to
                    install any apps or extension, you only need a Spotify
                    Track, Playlist, Album link to paste in the input field on
                    SpotifyUnload Spotify Music Downloader website and download
                    MP3 file format.
                </p>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Features - SpotifyUnload save Spotify songs to mp3:
                </h2>
                <ul className="list-disc px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Always stable and user-friendly, continuously updated
                        for the best experience
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        No need to download extension, Instead try our PWA
                        (Progressive Web Apps) feature for best experience.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Support all browsers and personal devices such as
                        phones, computers and tablets.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Easy to Use, Fast and always free
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        SpotifyUnload provide you with a simple and efficient
                        way to download your favorite Spotify songs 100% FREE.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Method download Spotify Tracks/Playlist/Album online?
                </h2>
                <ul className="list-decimal px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Open the Spotify app, or the Spotify website in your
                        browser.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Open the playlist or album and select song you want to
                        save as an Mp3 file.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Click the{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="19" cy="12" r="1" />{" "}
                                        <circle cx="5" cy="12" r="1" />
                                    </svg>
                                </span>
                                Three Dots{" "}
                            </span>{" "}
                            and click{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="6" cy="12" r="3" />{" "}
                                        <circle cx="18" cy="6" r="3" />{" "}
                                        <circle cx="18" cy="18" r="3" />{" "}
                                        <line
                                            x1="8.7"
                                            y1="10.7"
                                            x2="15.3"
                                            y2="7.3"
                                        />{" "}
                                        <line
                                            x1="8.7"
                                            y1="13.3"
                                            x2="15.3"
                                            y2="16.7"
                                        />
                                    </svg>
                                </span>
                                Share{" "}
                            </span>{" "}
                            button.
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Select{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <rect
                                            x="8"
                                            y="4"
                                            width="12"
                                            height="12"
                                            rx="2"
                                        />{" "}
                                        <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                                    </svg>
                                </span>
                                Copy Song Link{" "}
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Visit the website{" "}
                            <span className="flex items-end  text-[#1DB954] mx-2">
                                SpotifyUnload.com Spotify Downloader to MP3.
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Paste the Link into the above input field that says
                        "Paste URL from Spotify".
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Click the Download button on the right to download the
                        Mp3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Select Mp3 quality to download Spotify music Mp3.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>

            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414] text-2xl font-semibold mt-8">
                    FAQ
                </h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="mt-4">
                        <div
                            className="px-2 py-2 transition duration-100 ease-in-out border-2 rounded-md cursor-pointer"
                            onClick={() => toggleQuestion(index)}>
                            <h3 className="text-[#1DB954] text-lg font-medium select-none">
                                {faq.question}
                            </h3>
                        </div>
                        {activeQuestion === index && (
                            <p className="text-[#191414] text-lg mt-2 transition duration-100 ease-in-out">
                                {faq.answer}
                            </p>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};
