import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleChangeLanguage = (event) => {
        const selectedLanguage = event.target.value;

        if (selectedLanguage !== ".") navigate(`/${selectedLanguage}`);
    };

    return (
        <div>
            <div className="flex items-center justify-between px-6 py-2 border-b-2 shadow-sm xs:px-10">
                <div className="cursor-pointer" onClick={() => navigate("/")}>
                    <h1 className="font-sans text-5xl font-bold">
                        Spotify<span className="text-[#1DB954]">Unload</span>
                    </h1>
                </div>
                <div className="ml-4 ">
                    <select
                        id="languageSelector"
                        className="p-2 border-2 rounded-md cursor-pointer"
                        onChange={handleChangeLanguage}>
                        <option value=".">Language</option>
                        <option value="">English</option>
                        <option value="es">Español</option>
                        <option value="de">Deutsch</option>
                        <option value="fr">Français</option>
                        <option value="it">Italiano</option>
                        <option value="pt">Português</option>
                    </select>
                </div>
            </div>
        </div>
    );
};
