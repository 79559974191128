import React from "react";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";

export const PrivacyPolicy = () => {
        window.scrollTo(0, 0);

    return (
        <div className=" pt-4  ">
            <NavBar />
            <div className="mt-8 px-6  w-11/12 mx-auto max-w-3xl mb-10">
                <h3 className="font-semibold text-2xl xs:text-5xl font-serif ">
                    Privacy Policy
                </h3>
                <p className="mt-10 text-xl">
                    Please take a moment to familiarize yourself with our
                    privacy practices and contact us if you have any questions.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">
                        Personal identification information
                    </span>
                    <br />
                    <br />
                    We may collect personal identification information from
                    Users in a variety of ways, including, but not limited to,
                    when Users visit our site, fill out a form, and in
                    connection with other activities, services, features or
                    resources we make available on our Site. Users may visit our
                    Site anonymously. We will collect personal identification
                    information from Users only if they voluntarily submit such
                    information to us. Users can always refuse to supply
                    personally identification information, except that it may
                    prevent them from engaging in certain Site related
                    activities.
                </p>{" "}
                <p className="mt-4 text-xl">
                    <span className="font-bold">
                        Non-personal identification information
                    </span>
                    <br />
                    <br />
                    We may collect non-personal identification information about
                    Users whenever they interact with our Site. Non-personal
                    identification information may include the browser name, the
                    type of computer and technical information about Users means
                    of connection to our Site, such as the operating system and
                    the Internet service providers utilized and other similar
                    information. We may collect non-personal identification
                    information about Users whenever they interact with our
                    Site. Non-personal identification information may include
                    the browser name, the type of computer and technical
                    information about Users means of connection to our Site,
                    such as the operating system and the Internet service
                    providers utilized and other similar information.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">Web browser cookies</span>
                    <br />
                    <br />
                    We may collect non-personal identification information about
                    Users whenever they interact with our Site. Non-personal
                    identification information may include the browser name, the
                    type of computer and technical information about Users means
                    of connection to our Site, such as the operating system and
                    the Internet service providers utilized and other similar
                    information. We may collect non-personal identification
                    information about Users whenever they interact with our
                    Site. Non-personal identification information may include
                    the browser name, the type of computer and technical
                    information about Users means of connection to our Site,
                    such as the operating system and the Internet service
                    providers utilized and other similar information.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">
                        How we use collected information
                    </span>
                    <br />
                    <br />
                    SpotifyUnload.com does not collect or store your data,
                    period.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">Advertising</span>
                    <br />
                    <br />
                    Ads appearing on our site may be delivered to Users by
                    advertising partners, who may set cookies. These cookies
                    allow the ad server to recognize your computer each time
                    they send you an online advertisement to compile non
                    personal identification information about you or others who
                    use your computer. This information allows ad networks to,
                    among other things, deliver targeted advertisements that
                    they believe will be of most interest to you. This privacy
                    policy does not cover the use of cookies by any advertisers.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">Google Adsense</span>
                    <br />
                    <br />
                    Some of the ads may be served by Google. Google's use of the
                    DART cookie enables it to serve ads to Users based on their
                    visit to our Site and other sites on the Internet. DART uses
                    "non personally identifiable information" and does NOT track
                    personal information about you, such as your name, email
                    address, physical address, etc. You may opt out of the use
                    of the DART cookie by visiting the Google ad and content
                    network privacy policy at{" "}
                    <a
                        className="text-blue-500 cursor-pointer xs:text-xl text-sm"
                        href=" https://policies.google.com/technologies/ads"
                        target="_blank">
                        https://policies.google.com/technologies/ads
                    </a>
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">
                        Changes to this privacy policy
                    </span>
                    <br />
                    <br />
                    SpotifyUnload.com has the discretion to update this privacy
                    policy at any time. When we do, we will post a notification
                    on the main page of our Site, revise the updated date at the
                    bottom of this page. We encourage Users to frequently check
                    this page for any changes to stay informed about how we are
                    helping to protect the personal information we collect. You
                    acknowledge and agree that it is your responsibility to
                    review this privacy policy periodically and become aware of
                    modifications.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">
                        Your acceptance of these terms
                    </span>
                    <br />
                    <br />
                    By using this Site, you signify your acceptance of this
                    policy. If you do not agree to this policy, please do not
                    use our Site. Your continued use of the Site following the
                    posting of changes to this policy will be deemed your
                    acceptance of those changes.
                </p>
            </div>
            <Footer />
        </div>
    );
};
