import React from "react";
import { useNavigate } from "react-router-dom";
export const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className="mt-10 mb-20">
            <hr className="w-full my-10"></hr>
            <div className=" flex justify-around items-center  py-2  text-lg font-medium font-serif">
                <p
                    className="cursor-pointer hover:text-blue-500"
                    onClick={() => navigate("/contact")}>
                    Contact
                </p>
                <p
                    onClick={() => navigate("/terms-of-service")}
                    className="cursor-pointer hover:text-blue-500">
                    Terms of Service
                </p>
                <p
                    onClick={() => navigate("/privacy-policy")}
                    className="cursor-pointer hover:text-blue-500">
                    Privacy Policy
                </p>
            </div>
            <div className="text-center mt-4">
                <p className="text-lg">© 2023 All rights reserved. 2023</p>
                <p className="text-xl font-medium">
                    SpotifyUnload - Spotify Music Downloader
                </p>
            </div>
        </div>
    );
};
