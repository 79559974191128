import React from "react";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";

export const Contact = () => {
    window.scrollTo(0, 0);
    const navigate = useNavigate();
    return (
        <div className=" pt-4  ">
            <NavBar />
            <div className="mt-8 px-6  w-11/12 mx-auto max-w-3xl mb-10">
                <h3 className="font-semibold text-2xl xs:text-5xl font-serif ">
                    Contact us
                </h3>

                <p className="mt-10 text-xl">
                    We are here to answer any questions or inquiries that you
                    may have. Reach out to us and we will respond as soon as
                    possible. In the mean time please have a look at our{" "}
                    <span
                        onClick={() => navigate("/terms-of-service")}
                        className="text-[#1DB954] cursor-pointer hover:text-red-500">
                        terms of service.
                    </span>
                </p>

                <p className="my-10 text-xl">
                    <span className="text-2xl font-semibold">Mail: </span>
                    spotifyunload@gmail.com
                </p>
            </div>
            <Footer />
        </div>
    );
};
