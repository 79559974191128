import { Route, Routes } from "react-router-dom";
import "./App.css";

import { Home } from "./components/Home";
import { Contact } from "./components/Contact";
import { TermsOfService } from "./components/TermsOfService";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { HomeEs } from "./components/HomeEs";
import { HomeAle } from "./components/HomeAle";
import { HomeFra } from "./components/HomeFra";
import { HomeIta } from "./components/HomeIta";
import { HomePor } from "./components/HomePor";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/es" element={<HomeEs />} />
            <Route path="/de" element={<HomeAle />} />
            <Route path="/fr" element={<HomeFra />} />
            <Route path="/it" element={<HomeIta />} />
            <Route path="/pt" element={<HomePor />} />

            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    );
}

export default App;
