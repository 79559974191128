import React, { useEffect } from "react";

//hacer un modal que se abra cuando se haga click en el boton de descarga y que tenga una x para cerrar agregale ccs con tailwind para que se vea bonito con el fondo oscuro y en el medio el modal
export const ModalDescarga = ({ setModal }) => {
    const script3 = document.createElement("script");
    const script4 = document.createElement("script");
    // useEffect(() => {
    //     if (true) {
    //         script3.text = `
    //         atOptions = {
    //             'key' : '09822f19205d321c94895dca9d77cf68',
    //             'format' : 'iframe',
    //             'height' : 50,
    //             'width' : 320,
    //             'params' : {}
    //         };
    //     `;

    //         script4.src =
    //             "//www.topcreativeformat.com/09822f19205d321c94895dca9d77cf68/invoke.js";

    //         const adContainerButton =
    //             document.getElementById("adContainerButton");
    //         if (adContainerButton) {
    //             adContainerButton.appendChild(script3);
    //             adContainerButton.appendChild(script4);
    //         }

    //         return () => {
    //             if (adContainerButton) {
    //                 adContainerButton.removeChild(script3);
    //                 adContainerButton.removeChild(script4);
    //             }
    //         };
    //     }
    // }, [true]);
    return (
        <div className="fixed inset-0 z-40 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>
                <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                        <div className="">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                    className="text-lg font-medium leading-6 text-gray-900"
                                    id="modal-title">
                                    Downloading...
                                </h3>
                                <div className="w-full mt-2">
                                    <div
                                        className="flex justify-center w-full mx-auto"
                                        id="adContainerButton"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            onClick={() => setModal(false)}
                            type="button"
                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
