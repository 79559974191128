import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet";
import { ModalDescarga } from "./ModalDescarga";
const faqs = [
    {
        question: "Dois-je payer pour télécharger une chanson Spotify ?",
        answer: "Non, vous n'avez rien à payer, car notre service de téléchargement de chansons Spotify en format Mp3 est toujours gratuit ! Nous prenons en charge tous les navigateurs modernes tels que Google Chrome, Mozilla Firefox, Safari, Microsoft Edge, etc.",
    },
    {
        question:
            "Dois-je installer des extensions pour utiliser le téléchargeur de chansons Spotify ?",
        answer: "Non. Pour enregistrer une chanson Spotify au format mp3 en ligne, vous avez juste besoin d'un lien. Collez-le dans le champ de saisie et cliquez sur Télécharger. Notre application Spotify Downloader fera le reste.",
    },
    {
        question: "Pourquoi ne puis-je pas télécharger des chansons Spotify ?",
        answer: "Vous avez peut-être copié le mauvais lien Spotify, ou la connexion réseau est instable.",
    },
    {
        question:
            "Est-il possible de télécharger plusieurs chansons à la fois avec SpotifyUnload ?",
        answer: "C'est totalement illimité, nous ne limitons pas les téléchargements de SpotifyUnload. Vous pouvez télécharger n'importe quelle chanson Spotify au format Mp3.",
    },
    {
        question:
            "SpotifyUnload conserve-t-il une copie de la chanson téléchargée ?",
        answer: "Nous ne sauvegardons aucune information utilisateur, même des vidéos personnelles, vous pouvez être assuré que SpotifyUnload Spotify Downloader est complètement sécurisé.",
    },
    {
        question:
            "Où sont enregistrés les fichiers Spotify Mp3 après le téléchargement ?",
        answer: "Lorsque vous enregistrez depuis Spotify au format Mp3, les fichiers sont généralement sauvegardés dans votre emplacement par défaut. Dans les paramètres de votre navigateur, vous pouvez changer et sélectionner manuellement le dossier de destination de vos fichiers.",
    },
    {
        question:
            "SpotifyUnload prend-il en charge le téléchargement de listes de lecture Spotify ?",
        answer: "Nous prenons en charge le téléchargement de listes de lecture Spotify. Il vous suffit de coller le lien de la liste de lecture et de cliquer sur Télécharger. Vous pouvez également télécharger des albums Spotify.",
    },
];
export const HomeFra = () => {
    const [input, setInput] = useState("");
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [urlValida, setUrlValida] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [downloadError, setDownloadError] = useState(null);
    const toggleQuestion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };
    const [cargadoCancion, setCargandoCancion] = useState(false);
    const [isTrack, setIsTrack] = useState(null);
    const [isPlaylist, setIsPlaylist] = useState(null);
    const [isAlbum, setIsAlbum] = useState(null);
    const [modal, setModal] = useState(false);

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            setInput(text);
        });
    };
    const hacerLlamadaApi = async (url) => {
        let urlFormat = url?.trim();
        window.scrollTo(0, 0);
        if (urlFormat === "") {
            console.log("URL non valide");
            setUrlValida(false);
            return;
        }
        const regex =
            /^https:\/\/open\.spotify\.com\/(?:intl-es\/)?(track|playlist|album)\/([a-zA-Z0-9]+)(?:\?.*)?$/;
        const match = urlFormat?.match(regex);

        if (!match) {
            console.log("URL non valide");
            setUrlValida(false);
            return;
        }

        // Extraire le type et l'ID de la correspondance
        const [, tipo, id] = match;

        setCargando(true);
        setIsTrack(null);
        setIsPlaylist(null);
        setIsAlbum(null);
        setUrlValida(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROXY}/buscar-cancion`,

                {
                    idCancion: id,
                    tipo: tipo,
                }
            );

            if (tipo === "track") {
                setIsTrack(response.data);
            } else if (tipo === "playlist") {
                setIsPlaylist(response.data);
            } else if (tipo === "album") {
                setIsAlbum(response.data);
            } else {
                setUrlValida(false);
            }
            setCargando(false);
        } catch (error) {
            console.error(error);
            setUrlValida(false);
            setCargando(false);
        }
    };

    const handleDownload = async () => {
        setCargandoCancion(true);
        setModal(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_PROXY}/descargar-cancion/${isTrack.urlDescarga}/${isTrack.nombreCancion}`
            );

            if (!response.ok) {
                throw new Error("Erreur lors du téléchargement du fichier");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `SpotifyUnload.com - ${isTrack.nombreCancion}.m4a`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setCargandoCancion(false);
        } catch (error) {
            setCargandoCancion(false);
            console.error("Erreur lors du téléchargement du fichier :", error);
            setDownloadError("Erreur lors du téléchargement du fichier");
        }
    };

    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    // useEffect(() => {
    //     script1.text = `
    //         atOptions = {
    //             'key' : '201479128385db68aa1859ac8b99981e',
    //             'format' : 'iframe',
    //             'height' : 60,
    //             'width' : 468,
    //             'params' : {}
    //         };
    //     `;

    //     script2.src =
    //         "//www.topcreativeformat.com/201479128385db68aa1859ac8b99981e/invoke.js";

    //     const adContainer = document.getElementById("adContainer");
    //     if (adContainer) {
    //         adContainer.appendChild(script1);
    //         adContainer.appendChild(script2);
    //     }

    //     // Limpiar al desmontar
    //     return () => {
    //         if (adContainer) {
    //             adContainer.removeChild(script1);
    //             adContainer.removeChild(script2);
    //         }
    //     };
    // }, []);

    return (
        <div className="pt-4 pb-10 select-none ">
            {modal && <ModalDescarga setModal={setModal} />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    SpotifyUnload - Téléchargeur de musique Spotify gratuit
                </title>
                <link rel="icon" href="favicon.ico" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="⬇️🎶 SpotifyUnload est un outil gratuit qui vous aide à télécharger des chansons de Spotify au format MP3 en ligne. Enregistrez des chansons Spotify avec la meilleure qualité dans un fichier MP3. Notre Spotify Music Downloader est entièrement gratuit, rapide et sécurisé."
                />
                <link href="/dist/output.css" rel="stylesheet" />
                <link
                    rel="apple-touch-icon"
                    href="android-chrome-192x192.png"
                />

                <meta
                    name="keywords"
                    content="Spotify, Musique, Téléchargeur, MP3, Gratuit, Téléchargement"
                />

                <link rel="manifest" href="manifest.json" />
            </Helmet>

            <NavBar />
            <div className="mx-4 mt-8">
                <h3 className="font-serif text-2xl font-semibold xs:text-3xl">
                    Spotify Downloader en ligne
                </h3>
                <h3 className="mt-4 font-serif text-xl font-medium">
                    Téléchargez Spotify en MP3 gratuitement
                </h3>
            </div>
            <div
                className="flex justify-center w-full mx-auto"
                id="adContainer"></div>
            <div className="relative flex flex-col items-center justify-center mt-8 xs:flex-row">
                {!urlValida && (
                    <div className="absolute z-20 p-2 text-white transform -translate-x-1/2 bg-red-500 rounded-md -top-10 left-1/2">
                        L'URL saisie n'est pas valide. Veuillez la vérifier et
                        réessayer.
                    </div>
                )}
                <div className="relative w-11/12 max-w-2xl xs:w-9/12 md:w-6/12">
                    <input
                        placeholder="https://open.spotify.com/..../...."
                        className="border-4 border-[#191414] rounded-md xs:rounded-none xs:rounded-l-md py-4 w-full h-16 px-3 text-lg relative z-10"
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button
                        className="border-4 border-[#191414] rounded-md py-1 xs:py-2 px-1 xs:px-3 text-[#1DB954] font-bold xs:text-lg h-10 xs:h-12 bg-[#191414] flex justify-center items-center gap-2 absolute right-2 top-3 xs:top-2 bottom-0 z-10 hover:scale-105"
                        onClick={handlePaste}>
                        <p>Coller</p>
                        <span>
                            <svg
                                className="xs:h-6 xs:w-6 h-5 w-5  text-[#1DB954]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <rect
                                    x="8"
                                    y="4"
                                    width="12"
                                    height="12"
                                    rx="2"
                                />{" "}
                                <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                            </svg>
                        </span>
                    </button>
                </div>
                <button
                    disabled={cargando}
                    className=" disabled:cursor-not-allowed border-4 border-[#191414] mt-4 xs:mt-0 rounded-md xs:rounded-e-md xs:rounded-none py-3 px-3 text-[#1DB954] font-bold text-lg h-16 bg-[#191414] flex justify-center items-center gap-2"
                    onClick={() => hacerLlamadaApi(input)}>
                    <p>
                        {!cargando
                            ? "Télécharger"
                            : "Téléchargement en cours..."}
                    </p>
                    <span>
                        <svg
                            className={`h-6 w-6 text-[#1DB954] ${
                                cargando && "animate-bounce"
                            } `}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                        </svg>
                    </span>
                </button>
            </div>
            {isTrack && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isTrack.imagenCancion}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isTrack.nombreCancion}
                        </p>
                        <p className="text-lg font-medium">
                            {isTrack.nombreCantante}
                        </p>
                        <div>
                            <button
                                disabled={cargadoCancion}
                                onClick={handleDownload}
                                className="bg-[#1DB954] rounded-lg text-white text-lg font-medium py-2 px-10 hover:scale-105 disabled:cursor-not-allowed">
                                {cargadoCancion
                                    ? "Téléchargement en cours..."
                                    : "Télécharger"}
                            </button>
                            {downloadError && <p>{downloadError}</p>}
                        </div>
                    </div>
                </div>
            )}
            {isPlaylist && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isPlaylist.imagenPlaylist}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isPlaylist.nombrePlaylist}
                        </p>
                        <div className="w-full px-4">
                            {isPlaylist.cancionesPlaylist?.map(
                                (cancion, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between w-full mb-4">
                                        <div className="font-semibold xs:text-lg">
                                            <div className="text-[#1DB954]">
                                                {index + 1}: {cancion.nombre}
                                            </div>
                                            <div>{cancion.artista}</div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() =>
                                                    hacerLlamadaApi(
                                                        `https://open.spotify.com/intl-es/track/${cancion.id}`
                                                    )
                                                }
                                                className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                                Télécharger
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isAlbum && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isAlbum.imagenAlbum}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isAlbum.nombreAlbum}
                        </p>
                        <p className="text-lg font-medium">
                            {isAlbum.nombreCantante}
                        </p>
                        <div className="w-full px-4">
                            {isAlbum.cancionesAlbum?.map((cancion, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between w-full mb-4">
                                    <div className="font-semibold xs:text-lg">
                                        <div className="text-[#1DB954]">
                                            {index + 1}: {cancion.nombre}
                                        </div>
                                        <div>{cancion.artista}</div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() =>
                                                hacerLlamadaApi(
                                                    `https://open.spotify.com/intl-es/track/${cancion.id}`
                                                )
                                            }
                                            className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                            Télécharger
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Qu'est-ce que SpotifyUnload Spotify Downloader ?
                </h2>
                <p className="text-[#191414] text-lg mt-2">
                    SpotifyUnload est un outil gratuit qui vous aide à
                    télécharger des chansons Spotify en MP3 en ligne.
                    Enregistrez les chansons Spotify avec la meilleure qualité
                    au format MP3. Notre Spotify Music Downloader est totalement
                    gratuit, rapide et sécurisé. Pas besoin d'installer
                    d'applications ou d'extensions, vous avez seulement besoin
                    d'un lien de piste, de liste de lecture ou d'album Spotify à
                    coller dans le champ de saisie sur le site Web SpotifyUnload
                    Spotify Music Downloader et de télécharger le fichier MP3.
                </p>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Fonctionnalités - SpotifyUnload enregistre les chansons
                    Spotify en mp3 :
                </h2>
                <ul className="list-disc px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Toujours stable et convivial, continuellement mis à jour
                        pour la meilleure expérience
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Pas besoin de télécharger d'extension, essayez plutôt
                        notre fonction PWA (applications web progressives) pour
                        une meilleure expérience.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Prise en charge de tous les navigateurs et appareils
                        personnels tels que les téléphones, ordinateurs et
                        tablettes.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Facile à utiliser, rapide et toujours gratuit.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        SpotifyUnload vous propose une manière simple et
                        efficace de télécharger vos chansons Spotify préférées à
                        100% GRATUITEMENT.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Méthode pour télécharger des morceaux/playlist/albums de
                    Spotify en ligne
                </h2>
                <ul className="list-decimal px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Ouvrez l'application Spotify ou le site web de Spotify
                        dans votre navigateur.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Ouvrez la playlist ou l'album et sélectionnez la chanson
                        que vous souhaitez enregistrer au format MP3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Cliquez sur les{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="19" cy="12" r="1" />{" "}
                                        <circle cx="5" cy="12" r="1" />
                                    </svg>
                                </span>
                                Trois points{" "}
                            </span>{" "}
                            et cliquez sur{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="6" cy="12" r="3" />{" "}
                                        <circle cx="18" cy="6" r="3" />{" "}
                                        <circle cx="18" cy="18" r="3" />{" "}
                                        <line
                                            x1="8.7"
                                            y1="10.7"
                                            x2="15.3"
                                            y2="7.3"
                                        />{" "}
                                        <line
                                            x1="8.7"
                                            y1="13.3"
                                            x2="15.3"
                                            y2="16.7"
                                        />
                                    </svg>
                                </span>
                                Partager{" "}
                            </span>{" "}
                            button.
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Sélectionnez{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <rect
                                            x="8"
                                            y="4"
                                            width="12"
                                            height="12"
                                            rx="2"
                                        />{" "}
                                        <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                                    </svg>
                                </span>
                                Copier le lien de la chanson{" "}
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Visitez le site web{" "}
                            <span className="flex items-end  text-[#1DB954] mx-2">
                                SpotifyUnload.com Spotify Downloader to MP3.
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Collez le lien dans le champ ci-dessus qui dit "Coller
                        l'URL de Spotify".
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Cliquez sur le bouton Télécharger à droite pour
                        télécharger le Mp3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Sélectionnez la qualité Mp3 pour télécharger la musique
                        Spotify au format Mp3.
                    </li>
                </ul>
                <hr className="w-full my-10"></hr>

                <div className="w-11/12 mx-auto text-left">
                    <h2 className="text-[#191414] text-2xl font-semibold mt-8">
                        FAQ
                    </h2>
                    {faqs.map((faq, index) => (
                        <div key={index} className="mt-4">
                            <div
                                className="px-2 py-2 transition duration-100 ease-in-out border-2 rounded-md cursor-pointer"
                                onClick={() => toggleQuestion(index)}>
                                <h3 className="text-[#1DB954] text-lg font-medium select-none">
                                    {faq.question}
                                </h3>
                            </div>
                            {activeQuestion === index && (
                                <p className="text-[#191414] text-lg mt-2 transition duration-100 ease-in-out">
                                    {faq.answer}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
                <Footer />
            </div>
        </div>
    );
};
