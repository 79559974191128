import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <React.StrictMode>
            <CookieConsent
                location="bottom"
                buttonText="Ok!"
                style={{ background: "#2B373B" }}
                buttonStyle={{
                    color: "#000",

                    background: "#1DB954",
                    width: "100px",
                }}
                expires={150}>
                This website uses cookies to improve your experience. See our{" "}
                <a className=" text-[#1DB954]" href="/privacy-policy">
                    privacy policy
                </a>{" "}
                for more.
            </CookieConsent>
            <App />
        </React.StrictMode>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
