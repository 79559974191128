import React from "react";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";

export const TermsOfService = () => {
    window.scrollTo(0, 0);

    return (
        <div className=" pt-4  ">
            <NavBar />
            <div className="mt-8 px-6  w-11/12 mx-auto max-w-3xl mb-10">
                <h3 className="font-semibold text-2xl xs:text-5xl font-serif ">
                    Terms of Service
                </h3>
                <p className="mt-10 text-xl">
                    Set out below are the terms and conditions of your use of,
                    and access to, our website. Please read them carefully, as
                    they impose responsibilities on you in respect of your use
                    of Spotify Song Downloader.
                    <br />
                    <br />
                    By accessing this web site, you are agreeing to be bound by
                    these web site Terms and Conditions of Use, all applicable
                    laws and regulations, and agree that you are responsible for
                    compliance with any applicable local laws. If you do not
                    agree with any of these terms, you are prohibited from using
                    or accessing this site. The materials contained in this web
                    site are protected by applicable copyright and trademark
                    law.
                    <br />
                    <br />
                    <span className="font-bold">Use License</span>
                    <br />
                    <br />
                    Permission is granted to temporarily download one copy of
                    the materials (information or software) on
                    SpotifyUnload.com's website for personal, non-commercial
                    transitory viewing only. This is the grant of a license, not
                    a transfer of title, and under this license you may not:
                </p>
                <ul className="list-disc px-6  marker:text-2xl mt-3 ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Modify or copy the materials;
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Use the materials for any commercial purpose, or for any
                        public display (commercial or non-commercial);
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Remove any copyright or other proprietary notations from
                        the materials; or
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Transfer the materials to another person or "mirror" the
                        materials on any other server.
                    </li>
                </ul>
                <p className="mt-4 text-xl">
                    We are committed to conducting our business in accordance
                    with these principles in order to ensure that the
                    confidentiality of personal information is protected and
                    maintained.
                    <br />
                    <br />
                    <span className="font-bold">Disclaimer</span>
                    <br />
                    <br />
                    The materials on SpotifyUnload.com's website are provided
                    "as is". SpotifyUnload.com makes no warranties, expressed or
                    implied, and hereby disclaims and negates all other
                    warranties, including without limitation, implied warranties
                    or conditions of merchantability, fitness for a particular
                    purpose, or non-infringement of intellectual property or
                    other violation of rights. Further, SpotifyUnload.com does
                    not warrant or make any representations concerning the
                    accuracy, likely results, or reliability of the use of the
                    materials on its Internet web site or otherwise relating to
                    such materials or on any sites linked to this site.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">Limitations</span>
                    <br />
                    <br />
                    In no event shall SpotifyUnload.com or its suppliers be
                    liable for any damages (including, without limitation,
                    damages for loss of data or profit, or due to business
                    interruption,) arising out of the use or inability to use
                    the materials on SpotifyUnload.com's Internet site, even if
                    SpotifyUnload.com or a SpotifyUnload.com authorized
                    representative has been notified orally or in writing of the
                    possibility of such damage. Because some jurisdictions do
                    not allow limitations on implied warranties, or limitations
                    of liability for consequential or incidental damages, these
                    limitations may not apply to you.
                </p>{" "}
                <p className="mt-4 text-xl">
                    <span className="font-bold">Revisions and Errata</span>
                    <br />
                    <br />
                    The materials appearing on SpotifyUnload.com's website could
                    include technical, typographical, or photographic errors.
                    SpotifyUnload.com.com does not warrant that any of the
                    materials on its web site are accurate, complete, or
                    current. SpotifyUnload.com may make changes to the materials
                    contained on its web site at any time without notice.
                    SpotifyUnload.com does not, however, make any commitment to
                    update the materials.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">Links</span>
                    <br />
                    <br />
                    SpotifyUnload.com has not reviewed all of the sites linked
                    to its Internet web site and is not responsible for the
                    contents of any such linked site. The inclusion of any link
                    does not imply endorsement by SpotifyUnload.com of the site.
                    Use of any such linked web site is at the user's own risk.
                </p>
                <p className="mt-4 text-xl">
                    <span className="font-bold">
                        Site Terms of Use Modifications
                    </span>
                    <br />
                    <br />
                    SpotifyUnload.com may revise these terms of use for its web
                    site at any time without notice. By using this web site you
                    are agreeing to be bound by the then current version of
                    these Terms and Conditions of Use. General Terms and
                    Conditions applicable to Use of a Web Site.
                </p>
            </div>
            <Footer />
        </div>
    );
};
