import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet";
import { ModalDescarga } from "./ModalDescarga";

const faqs = [
    {
        question: "Preciso pagar para baixar músicas do Spotify?",
        answer: "Não, você não precisa pagar por nada, porque nosso serviço de download de músicas do Spotify para MP3 é sempre gratuito! Oferecemos suporte a todos os navegadores modernos, como Google Chrome, Mozilla Firefox, Safari, Microsoft Edge, etc.",
    },
    {
        question:
            "Preciso instalar extensões para usar o baixador de músicas do Spotify?",
        answer: "Não. Para salvar músicas do Spotify em MP3 online, você só precisa de um link. Cole-o no campo de entrada e clique em Download. Nosso aplicativo de download do Spotify cuidará do resto.",
    },
    {
        question: "Por que não consigo baixar músicas do Spotify?",
        answer: "Talvez você tenha copiado o link errado do Spotify ou a conexão de rede esteja instável.",
    },
    {
        question:
            "É possível baixar várias músicas de uma vez no SpotifyUnload?",
        answer: "É completamente ilimitado, não limitamos os downloads do SpotifyUnload. Você pode baixar qualquer música do Spotify como MP3.",
    },
    {
        question: "O SpotifyUnload mantém uma cópia da música baixada?",
        answer: "Não fazemos backup de nenhuma informação do usuário, nem mesmo de vídeos pessoais, então você pode ter certeza de que o SpotifyUnload Spotify Downloader é completamente seguro.",
    },
    {
        question:
            "Onde as músicas em MP3 do Spotify são salvas após o download?",
        answer: "Quando você salva do Spotify para MP3, os arquivos geralmente são salvos em sua localização padrão. Nas configurações do seu navegador, você pode alterar e selecionar manualmente a pasta de destino para seus arquivos.",
    },
    {
        question:
            "O SpotifyUnload oferece suporte ao download de playlists do Spotify?",
        answer: "Temos suporte para baixar playlists do Spotify. Basta colar o link da playlist e clicar em Download. Você também pode baixar álbuns do Spotify.",
    },
];

export const HomePor = () => {
    const [input, setInput] = useState("");
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [urlValida, setUrlValida] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [downloadError, setDownloadError] = useState(null);

    const toggleQuestion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };

    const [cargadoCancion, setCargandoCancion] = useState(false);
    const [isTrack, setIsTrack] = useState(null);
    const [isPlaylist, setIsPlaylist] = useState(null);
    const [isAlbum, setIsAlbum] = useState(null);
    const [modal, setModal] = useState(false);

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            setInput(text);
        });
    };

    const hacerLlamadaApi = async (url) => {
        let urlFormat = url?.trim();
        window.scrollTo(0, 0);
        if (urlFormat === "") {
            console.log("URL non valide");
            setUrlValida(false);
            return;
        }
        const regex =
            /^https:\/\/open\.spotify\.com\/(?:intl-es\/)?(track|playlist|album)\/([a-zA-Z0-9]+)(?:\?.*)?$/;
        const match = urlFormat?.match(regex);

        if (!match) {
            console.log("URL non valide");
            setUrlValida(false);
            return;
        }

        // Extraire le type et l'ID de la correspondance
        const [, tipo, id] = match;

        setCargando(true);
        setIsTrack(null);
        setIsPlaylist(null);
        setIsAlbum(null);
        setUrlValida(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROXY}/buscar-cancion`,

                {
                    idCancion: id,
                    tipo: tipo,
                }
            );

            if (tipo === "track") {
                setIsTrack(response.data);
            } else if (tipo === "playlist") {
                setIsPlaylist(response.data);
            } else if (tipo === "album") {
                setIsAlbum(response.data);
            } else {
                setUrlValida(false);
            }
            setCargando(false);
        } catch (error) {
            console.error(error);
            setUrlValida(false);
            setCargando(false);
        }
    };

    const handleDownload = async () => {
        setCargandoCancion(true);
        setModal(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_PROXY}/descargar-cancion/${isTrack.urlDescarga}/${isTrack.nombreCancion}`
            );

            if (!response.ok) {
                throw new Error("Erro ao baixar o arquivo");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `SpotifyUnload.com - ${isTrack.nombreCancion}.m4a`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setCargandoCancion(false);
        } catch (error) {
            setCargandoCancion(false);
            console.error("Erro ao baixar o arquivo:", error);
            setDownloadError("Erro ao baixar o arquivo");
        }
    };

    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    // useEffect(() => {
    //     script1.text = `
    //         atOptions = {
    //             'key' : '201479128385db68aa1859ac8b99981e',
    //             'format' : 'iframe',
    //             'height' : 60,
    //             'width' : 468,
    //             'params' : {}
    //         };
    //     `;

    //     script2.src =
    //         "//www.topcreativeformat.com/201479128385db68aa1859ac8b99981e/invoke.js";

    //     const adContainer = document.getElementById("adContainer");
    //     if (adContainer) {
    //         adContainer.appendChild(script1);
    //         adContainer.appendChild(script2);
    //     }

    //     // Limpiar al desmontar
    //     return () => {
    //         if (adContainer) {
    //             adContainer.removeChild(script1);
    //             adContainer.removeChild(script2);
    //         }
    //     };
    // }, []);

    return (
        <div className="pt-4 pb-10 select-none">
            {modal && <ModalDescarga setModal={setModal} />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    SpotifyUnload - Download gratuito de música do Spotify
                </title>
                <link rel="icon" href="favicon.ico" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="⬇️🎶 SpotifyUnload é uma ferramenta gratuita que ajuda você a baixar músicas do Spotify em MP3 online. Salve músicas do Spotify com a melhor qualidade em um formato MP3. Nosso Spotify Music Downloader é totalmente gratuito, rápido e seguro."
                />
                <link href="/dist/output.css" rel="stylesheet" />
                <link
                    rel="apple-touch-icon"
                    href="android-chrome-192x192.png"
                />

                <meta
                    name="keywords"
                    content="Spotify, Música, Downloader, MP3, Gratuito, Download"
                />

                <link rel="manifest" href="manifest.json" />
            </Helmet>

            <NavBar />
            <div className="mx-4 mt-8">
                <h3 className="font-serif text-2xl font-semibold xs:text-3xl">
                    Spotify Downloader Online
                </h3>
                <h3 className="mt-4 font-serif text-xl font-medium">
                    Baixar Spotify para MP3 Grátis
                </h3>
            </div>
            <div
                className="flex justify-center w-full mx-auto"
                id="adContainer"></div>
            <div className="relative flex flex-col items-center justify-center mt-8 xs:flex-row">
                {!urlValida && (
                    <div className="absolute z-20 p-2 text-white transform -translate-x-1/2 bg-red-500 rounded-md -top-10 left-1/2">
                        O URL inserido é inválido. Por favor, verifique e tente
                        novamente.
                    </div>
                )}
                <div className="relative w-11/12 max-w-2xl xs:w-9/12 md:w-6/12">
                    <input
                        placeholder="https://open.spotify.com/..../...."
                        className="border-4 border-[#191414] rounded-md xs:rounded-none xs:rounded-l-md py-4 w-full h-16 px-3 text-lg relative z-10"
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button
                        className="border-4 border-[#191414] rounded-md py-1 xs:py-2 px-1 xs:px-3 text-[#1DB954] font-bold xs:text-lg h-10 xs:h-12 bg-[#191414] flex justify-center items-center gap-2 absolute right-2 top-3 xs:top-2 bottom-0 z-10 hover:scale-105"
                        onClick={handlePaste}>
                        <p>Colar</p>
                        <span>
                            <svg
                                className="xs:h-6 xs:w-6 h-5 w-5 text-[#1DB954]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <rect
                                    x="8"
                                    y="4"
                                    width="12"
                                    height="12"
                                    rx="2"
                                />
                                <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                            </svg>
                        </span>
                    </button>
                </div>
                <button
                    disabled={cargando}
                    className="disabled:cursor-not-allowed border-4 border-[#191414] mt-4 xs:mt-0 rounded-md xs:rounded-e-md xs:rounded-none py-3 px-3 text-[#1DB954] font-bold text-lg h-16 bg-[#191414] flex justify-center items-center gap-2"
                    onClick={() => hacerLlamadaApi(input)}>
                    <p>{!cargando ? "Baixar" : "Baixando..."}</p>
                    <span>
                        <svg
                            className={`h-6 w-6 text-[#1DB954] ${
                                cargando && "animate-bounce"
                            }`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                        </svg>
                    </span>
                </button>
            </div>
            {isTrack && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isTrack.imagenCancion}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isTrack.nomeCancion}
                        </p>
                        <p className="text-lg font-medium">
                            {isTrack.nomeCantante}
                        </p>
                        <div>
                            <button
                                disabled={cargadoCancion}
                                onClick={handleDownload}
                                className="bg-[#1DB954] rounded-lg text-white text-lg font-medium py-2 px-10 hover:scale-105 disabled:cursor-not-allowed">
                                {cargadoCancion ? "Baixando..." : "Baixar"}
                            </button>
                            {downloadError && <p>{downloadError}</p>}
                        </div>
                    </div>
                </div>
            )}
            {isPlaylist && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isPlaylist.imagenPlaylist}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isPlaylist.nomePlaylist}
                        </p>
                        <div className="w-full px-4">
                            {isPlaylist.cancionesPlaylist?.map(
                                (cancion, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between w-full mb-4">
                                        <div className="font-semibold xs:text-lg">
                                            <div className="text-[#1DB954]">
                                                {index + 1}: {cancion.nome}
                                            </div>
                                            <div>{cancion.artista}</div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() =>
                                                    hacerLlamadaApi(
                                                        `https://open.spotify.com/intl-pt/track/${cancion.id}`
                                                    )
                                                }
                                                className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                                Baixar
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isAlbum && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isAlbum.imagenAlbum}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isAlbum.nomeAlbum}
                        </p>
                        <p className="text-lg font-medium">
                            {isAlbum.nomeCantante}
                        </p>
                        <div className="w-full px-4">
                            {isAlbum.cancionesAlbum?.map((cancion, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between w-full mb-4">
                                    <div className="font-semibold xs:text-lg">
                                        <div className="text-[#1DB954]">
                                            {index + 1}: {cancion.nome}
                                        </div>
                                        <div>{cancion.artista}</div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() =>
                                                hacerLlamadaApi(
                                                    `https://open.spotify.com/intl-pt/track/${cancion.id}`
                                                )
                                            }
                                            className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                            Baixar
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    O que é o SpotifyUnload Spotify Downloader?
                </h2>
                <p className="text-[#191414] text-lg mt-2">
                    O SpotifyUnload é uma ferramenta gratuita que ajuda você a
                    baixar músicas do Spotify em MP3 online. Salve músicas do
                    Spotify com a melhor qualidade em formato MP3. Nosso Spotify
                    Music Downloader é completamente gratuito, rápido e seguro.
                    Não é necessário instalar nenhum aplicativo ou extensão,
                    você só precisa de um link de faixa, playlist ou álbum do
                    Spotify para colar no campo de entrada do SpotifyUnload
                    Spotify Music Downloader e baixar no formato MP3.
                </p>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Recursos - SpotifyUnload salva músicas do Spotify em mp3:
                </h2>
                <ul className="list-disc px-6 marker:text-[#1DB954] marker:text-2xl">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Sempre estável e fácil de usar, continuamente atualizado
                        para a melhor experiência.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Não é necessário baixar extensões. Em vez disso,
                        experimente nosso recurso PWA (Progressive Web Apps)
                        para a melhor experiência.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Suporte para todos os navegadores e dispositivos
                        pessoais, como telefones, computadores e tablets.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Fácil de usar, rápido e sempre gratuito.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        O SpotifyUnload fornece uma maneira simples e eficiente
                        de baixar suas músicas favoritas do Spotify 100% GRÁTIS.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414] text-2xl font-semibold">
                    Método para baixar músicas/playlist/álbum do Spotify online?
                </h2>
                <ul className="list-decimal px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Abra o aplicativo Spotify ou o site do Spotify no seu
                        navegador.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Abra a playlist ou álbum e selecione a música que deseja
                        salvar como um arquivo Mp3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Clique nos{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="19" cy="12" r="1" />{" "}
                                        <circle cx="5" cy="12" r="1" />
                                    </svg>
                                </span>
                                Três Pontos{" "}
                            </span>{" "}
                            e clique{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="6" cy="12" r="3" />{" "}
                                        <circle cx="18" cy="6" r="3" />{" "}
                                        <circle cx="18" cy="18" r="3" />{" "}
                                        <line
                                            x1="8.7"
                                            y1="10.7"
                                            x2="15.3"
                                            y2="7.3"
                                        />{" "}
                                        <line
                                            x1="8.7"
                                            y1="13.3"
                                            x2="15.3"
                                            y2="16.7"
                                        />
                                    </svg>
                                </span>
                                Compartilhar{" "}
                            </span>{" "}
                            button.
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Selecione{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <rect
                                            x="8"
                                            y="4"
                                            width="12"
                                            height="12"
                                            rx="2"
                                        />{" "}
                                        <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                                    </svg>
                                </span>
                                Copiar Link da Música{" "}
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Visite o site{" "}
                            <span className="flex items-end  text-[#1DB954] mx-2">
                                SpotifyUnload.com Spotify Downloader para MP3.
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Cole o link no campo de entrada acima que diz "Cole o
                        URL do Spotify".
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Clique no botão Download à direita para baixar o Mp3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Selecione a qualidade do Mp3 para baixar músicas do
                        Spotify em Mp3.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>

            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414] text-2xl font-semibold mt-8">
                    FAQ
                </h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="mt-4">
                        <div
                            className="px-2 py-2 transition duration-100 ease-in-out border-2 rounded-md cursor-pointer"
                            onClick={() => toggleQuestion(index)}>
                            <h3 className="text-[#1DB954] text-lg font-medium select-none">
                                {faq.question}
                            </h3>
                        </div>
                        {activeQuestion === index && (
                            <p className="text-[#191414] text-lg mt-2 transition duration-100 ease-in-out">
                                {faq.answer}
                            </p>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};
