import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet";
import { ModalDescarga } from "./ModalDescarga";
const faqs = [
    {
        question: "¿Tengo que pagar para descargar canciones de Spotify?",
        answer: "No, no tienes que pagar nada, ¡nuestro servicio para descargar canciones de Spotify a Mp3 siempre es gratuito! Soportamos todos los navegadores modernos como Google Chrome, Mozilla Firefox, Safari, Microsoft Edge, etc.",
    },
    {
        question:
            "¿Necesito instalar extensiones para usar el descargador de canciones de Spotify?",
        answer: "No. Para guardar Spotify en mp3 en línea, solo necesitas un enlace. Péga-lo en el campo de entrada y haz clic en Descargar. Nuestra aplicación Spotify Downloader hará el resto.",
    },
    {
        question: "¿Por qué no puedo descargar canciones de Spotify?",
        answer: "Quizás copiaste el enlace incorrecto de Spotify o la conexión de red no es estable.",
    },
    {
        question:
            "¿Es posible descargar varias canciones a la vez en SpotifyUnload?",
        answer: "Es completamente ilimitado, no limitamos las descargas de SpotifyUnload, puedes descargar cualquier canción de Spotify como un Mp3.",
    },
    {
        question: "¿SpotifyUnload guarda una copia de la canción descargada?",
        answer: "No respaldamos ninguna información del usuario, ni siquiera videos personales, así que puedes estar seguro de que SpotifyUnload Spotify Downloader es completamente seguro.",
    },
    {
        question:
            "¿Dónde se guardan los archivos Mp3 de Spotify después de descargarlos?",
        answer: "Cuando guardas desde Spotify a Mp3, los archivos suelen guardarse en tu ubicación predeterminada. En la configuración de tu navegador, puedes cambiar y seleccionar manualmente la carpeta de destino para tus archivos.",
    },
    {
        question:
            "¿SpotifyUnload soporta la descarga de listas de reproducción de Spotify?",
        answer: "Tenemos soporte para descargar listas de reproducción de Spotify. Solo pega el enlace de la lista de reproducción y haz clic en Descargar. También puedes descargar álbumes de Spotify.",
    },
];
export const HomeEs = () => {
    const [input, setInput] = useState("");
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [urlValida, setUrlValida] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [downloadError, setDownloadError] = useState(null);
    const toggleQuestion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };
    const [modal, setModal] = useState(false);

    const [cargadoCancion, setCargandoCancion] = useState(false);
    const [isTrack, setIsTrack] = useState(null);
    const [isPlaylist, setIsPlaylist] = useState(null);
    const [isAlbum, setIsAlbum] = useState(null);

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            setInput(text);
        });
    };
    const hacerLlamadaApi = async (url) => {
        let urlFormat = url?.trim();
        window.scrollTo(0, 0);
        if (urlFormat === "") {
            console.log("URL no válida");
            setUrlValida(false);
            return;
        }
        const regex =
            /^https:\/\/open\.spotify\.com\/(?:intl-es\/)?(track|playlist|album)\/([a-zA-Z0-9]+)(?:\?.*)?$/;
        const match = urlFormat?.match(regex);

        if (!match) {
            console.log("URL no válida");
            setUrlValida(false);
            return;
        }

        // Extraer el tipo y el ID de la coincidencia
        const [, tipo, id] = match;

        setCargando(true);
        setIsTrack(null);
        setIsPlaylist(null);
        setIsAlbum(null);
        setUrlValida(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROXY}/buscar-cancion`,

                {
                    idCancion: id,
                    tipo: tipo,
                }
            );

            if (tipo === "track") {
                setIsTrack(response.data);
            } else if (tipo === "playlist") {
                setIsPlaylist(response.data);
            } else if (tipo === "album") {
                setIsAlbum(response.data);
            } else {
                setUrlValida(false);
            }
            setCargando(false);
        } catch (error) {
            console.error(error);
            setUrlValida(false);
            setCargando(false);
        }
    };

    const handleDownload = async () => {
        setCargandoCancion(true);
        setModal(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_PROXY}/descargar-cancion/${isTrack.urlDescarga}/${isTrack.nombreCancion}`
            );

            if (!response.ok) {
                throw new Error("Error al descargar el archivo");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `SpotifyUnload.com - ${isTrack.nombreCancion}.m4a`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setCargandoCancion(false);
        } catch (error) {
            setCargandoCancion(false);
            console.error("Error al descargar el archivo:", error);
            setDownloadError("Error al descargar el archivo");
        }
    };
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    // useEffect(() => {
    //     script1.text = `
    //         atOptions = {
    //             'key' : '201479128385db68aa1859ac8b99981e',
    //             'format' : 'iframe',
    //             'height' : 60,
    //             'width' : 468,
    //             'params' : {}
    //         };
    //     `;

    //     script2.src =
    //         "//www.topcreativeformat.com/201479128385db68aa1859ac8b99981e/invoke.js";

    //     const adContainer = document.getElementById("adContainer");
    //     if (adContainer) {
    //         adContainer.appendChild(script1);
    //         adContainer.appendChild(script2);
    //     }

    //     // Limpiar al desmontar
    //     return () => {
    //         if (adContainer) {
    //             adContainer.removeChild(script1);
    //             adContainer.removeChild(script2);
    //         }
    //     };
    // }, []);

    return (
        <div className="pt-4 pb-10 select-none ">
            {modal && <ModalDescarga setModal={setModal} />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    SpotifyUnload - Descargador gratuito de música de Spotify
                </title>
                <link rel="icon" href="favicon.ico" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="⬇️🎶 SpotifyUnload es una herramienta gratuita que te ayuda a descargar canciones de Spotify en formato MP3 en línea. Guarda canciones de Spotify con la mejor calidad en un archivo MP3. Nuestro Descargador de Música de Spotify es completamente gratuito, rápido y seguro."
                />
                <link href="/dist/output.css" rel="stylesheet" />
                <link
                    rel="apple-touch-icon"
                    href="android-chrome-192x192.png"
                />

                <meta
                    name="keywords"
                    content="Spotify, Música, Descargador, MP3, Gratis, Descarga"
                />

                <link rel="manifest" href="manifest.json" />
            </Helmet>
            <NavBar />
            <div className="mx-4 mt-8">
                <h3 className="font-serif text-2xl font-semibold xs:text-3xl">
                    Descargador de Spotify en línea
                </h3>
                <h3 className="mt-4 font-serif text-xl font-medium">
                    Descarga Spotify a MP3 gratis
                </h3>
            </div>
            <div
                className="flex justify-center w-full mx-auto"
                id="adContainer"></div>
            <div className="relative flex flex-col items-center justify-center mt-8 xs:flex-row">
                {!urlValida && (
                    <div className="absolute z-20 p-2 text-white transform -translate-x-1/2 bg-red-500 rounded-md -top-10 left-1/2">
                        La URL ingresada no es válida. Por favor, revísala e
                        inténtalo nuevamente.
                    </div>
                )}
                <div className="relative w-11/12 max-w-2xl xs:w-9/12 md:w-6/12">
                    <input
                        placeholder="https://open.spotify.com/..../...."
                        className="border-4 border-[#191414] rounded-md xs:rounded-none xs:rounded-l-md py-4 w-full h-16 px-3 text-lg relative z-10"
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <button
                        className="border-4 border-[#191414] rounded-md py-1 xs:py-2 px-1 xs:px-3  text-[#1DB954] font-bold xs:text-lg h-10 xs:h-12 bg-[#191414] flex justify-center items-center gap-2 absolute right-2 top-3 xs:top-2 bottom-0 z-10 hover:scale-105"
                        onClick={handlePaste}>
                        <p>Pegar</p>
                        <span>
                            <svg
                                className="xs:h-6 xs:w-6 h-5 w-5  text-[#1DB954]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <rect
                                    x="8"
                                    y="4"
                                    width="12"
                                    height="12"
                                    rx="2"
                                />{" "}
                                <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                            </svg>
                        </span>
                    </button>
                </div>
                <button
                    disabled={cargando}
                    className=" disabled:cursor-not-allowed border-4 border-[#191414] mt-4 xs:mt-0 rounded-md xs:rounded-e-md xs:rounded-none py-3 px-3 text-[#1DB954] font-bold text-lg h-16 bg-[#191414] flex justify-center items-center gap-2"
                    onClick={() => hacerLlamadaApi(input)}>
                    <p>{!cargando ? "Descargar" : "Descargando..."}</p>
                    <span>
                        <svg
                            className={`h-6 w-6 text-[#1DB954] ${
                                cargando && "animate-bounce"
                            }`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                        </svg>
                    </span>
                </button>
            </div>
            {isTrack && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isTrack.imagenCancion}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isTrack.nombreCancion}
                        </p>
                        <p className="text-lg font-medium">
                            {isTrack.nombreCantante}
                        </p>
                        <div>
                            <button
                                disabled={cargadoCancion}
                                onClick={handleDownload}
                                className="bg-[#1DB954] rounded-lg text-white text-lg font-medium py-2 px-10 hover:scale-105 disabled:cursor-not-allowed">
                                {cargadoCancion
                                    ? "Descargando..."
                                    : "Descargar"}
                            </button>
                            {downloadError && <p>{downloadError}</p>}
                        </div>
                    </div>
                </div>
            )}
            {isPlaylist && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isPlaylist.imagenPlaylist}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isPlaylist.nombrePlaylist}
                        </p>
                        <div className="w-full px-4">
                            {isPlaylist.cancionesPlaylist?.map(
                                (cancion, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between w-full mb-4">
                                        <div className="font-semibold xs:text-lg">
                                            <div className="text-[#1DB954]">
                                                {index + 1}: {cancion.nombre}
                                            </div>
                                            <div>{cancion.artista}</div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() =>
                                                    hacerLlamadaApi(
                                                        `https://open.spotify.com/intl-es/track/${cancion.id}`
                                                    )
                                                }
                                                className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isAlbum && (
                <div>
                    <hr className="w-full my-10"></hr>
                    <div className="flex flex-col items-center justify-center w-11/12 gap-3 py-6 mx-auto bg-slate-50">
                        <img
                            src={isAlbum.imagenAlbum}
                            className="rounded-lg w-52 h-52 xs:w-72 xs:h-72"
                        />
                        <p className="text-2xl font-bold">
                            {isAlbum.nombreAlbum}
                        </p>
                        <p className="text-lg font-medium">
                            {isAlbum.nombreCantante}
                        </p>
                        <div className="w-full px-4">
                            {isAlbum.cancionesAlbum?.map((cancion, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between w-full mb-4">
                                    <div className="font-semibold xs:text-lg">
                                        <div className="text-[#1DB954]">
                                            {index + 1}: {cancion.nombre}
                                        </div>
                                        <div>{cancion.artista}</div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() =>
                                                hacerLlamadaApi(
                                                    `https://open.spotify.com/intl-es/track/${cancion.id}`
                                                )
                                            }
                                            className="bg-[#1DB954] rounded-lg text-white xs:text-lg font-medium py-2 px-4 xs:px-6 hover:scale-105">
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    ¿Qué es SpotifyUnload Spotify Downloader?
                </h2>
                <p className="text-[#191414] text-lg mt-2">
                    SpotifyUnload es una herramienta gratuita que te ayuda a
                    descargar canciones de Spotify en MP3 en línea. Guarda
                    canciones de Spotify con la mejor calidad en formato de
                    archivo MP3. Nuestro descargador de música de Spotify es
                    completamente gratuito, rápido y seguro. No es necesario
                    instalar aplicaciones ni extensiones, solo necesitas un
                    enlace de Spotify Track, Playlist o Album para pegar en el
                    campo de entrada en el sitio web de SpotifyUnload Spotify
                    Music Downloader y descargar el archivo MP3.
                </p>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Características - SpotifyUnload guarda canciones de Spotify
                    en mp3:
                </h2>
                <ul className="list-disc px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Siempre estable y fácil de usar, actualizado
                        continuamente para la mejor experiencia.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        No es necesario descargar extensiones, prueba nuestra
                        función de Aplicaciones Web Progresivas (PWA) para la
                        mejor experiencia.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Compatible con todos los navegadores y dispositivos
                        personales como teléfonos, computadoras y tabletas.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Fácil de usar, rápido y siempre gratuito.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        SpotifyUnload te proporciona una forma simple y
                        eficiente de descargar tus canciones favoritas de
                        Spotify ¡100% GRATIS!
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>
            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414]  text-2xl font-semibold">
                    Método para descargar pistas/playlist/álbum de Spotify en
                    línea
                </h2>
                <ul className="list-decimal px-6 marker:text-[#1DB954] marker:text-2xl ">
                    <li className="text-[#191414] text-lg mt-2 ">
                        Abre la aplicación de Spotify o el sitio web de Spotify
                        en tu navegador.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Abre la lista de reproducción o el álbum y selecciona la
                        canción que deseas guardar como archivo Mp3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Haz clic en los{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="19" cy="12" r="1" />{" "}
                                        <circle cx="5" cy="12" r="1" />
                                    </svg>
                                </span>
                                Tres puntos{" "}
                            </span>{" "}
                            y haz clic en el botón{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <rect
                                            x="8"
                                            y="4"
                                            width="12"
                                            height="12"
                                            rx="2"
                                        />{" "}
                                        <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                                    </svg>
                                </span>
                                Compartir{" "}
                            </span>{" "}
                            .
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Selecciona{" "}
                            <span className="flex items-end  text-[#1DB954] mr-2">
                                {" "}
                                <span>
                                    <svg
                                        className="h-6 w-6 text-[#1DB954] ml-2"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <rect
                                            x="8"
                                            y="4"
                                            width="12"
                                            height="12"
                                            rx="2"
                                        />{" "}
                                        <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                                    </svg>
                                </span>
                                Copiar Enlace de la Canción{" "}
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        <p className="flex">
                            Visita el sitio web{" "}
                            <span className="flex items-end  text-[#1DB954] mx-2">
                                SpotifyUnload.com Spotify Downloader to MP3.
                            </span>{" "}
                        </p>
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Pega el enlace en el campo de entrada de arriba que dice
                        "Pegar URL de Spotify".
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Haz clic en el botón de Descargar a la derecha para
                        descargar el Mp3.
                    </li>
                    <li className="text-[#191414] text-lg mt-2 ">
                        Selecciona la calidad de Mp3 para descargar música de
                        Spotify.
                    </li>
                </ul>
            </div>
            <hr className="w-full my-10"></hr>

            <div className="w-11/12 mx-auto text-left">
                <h2 className="text-[#191414] text-2xl font-semibold mt-8">
                    FAQ
                </h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="mt-4">
                        <div
                            className="px-2 py-2 transition duration-100 ease-in-out border-2 rounded-md cursor-pointer"
                            onClick={() => toggleQuestion(index)}>
                            <h3 className="text-[#1DB954] text-lg font-medium select-none">
                                {faq.question}
                            </h3>
                        </div>
                        {activeQuestion === index && (
                            <p className="text-[#191414] text-lg mt-2 transition duration-100 ease-in-out">
                                {faq.answer}
                            </p>
                        )}
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};
